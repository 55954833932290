import { BigNumberish } from 'ethers';
import { BidHistoryObj } from 'global/interfaces';
import { weiToETH } from './price';

const bidHistoryMap = (offers: any): BidHistoryObj[] => {
  const length = offers?.bid?.length;
  let BidHistory: BidHistoryObj[] = [];
  for (let i = 0; i < length; i++) {
    BidHistory.push({
      bid: weiToETH(offers.bid[i]),
      bidder: offers.bidder[i],
      time: Number(offers.timestamp[i].toString()) * 1000,
    });
  }
  return BidHistory;
};

export default bidHistoryMap;
