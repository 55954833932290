import React from 'react';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import useData from 'store/data';
import useLanguageStore from 'store/language';
import getCurrentLocale from 'locales/get-current-locale';
import { Locale } from 'global/interfaces';
import locales from 'locales';
import { useIntl } from 'react-intl';
import { LocaleType } from 'global/types';
import useLocale from 'locales/useLocale';
type Props = {};

const LocaleIcon = styled(IconButton)(({ theme }) => ({
  marginInlineStart: 7,
  background: theme.palette.grey[200],
}));

const LanguageSelector = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {currentLocale, setLocale } = useLocale();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'langs-popover' : undefined;
  const handleChangeLanguage = (locale: LocaleType) => {
    handleClose();
    setLocale(locale);
  };
  
  return (
    <div>
      <LocaleIcon aria-describedby={id} onClick={handleClick}>
        <img className="rounded" width={20} height={15} src={currentLocale.iconSrc} />
      </LocaleIcon>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {locales.map(locale => (
            <ListItemButton onClick={() => handleChangeLanguage(locale.locale)} sx={{ textAlign: 'center' }}>
              <img className="rounded" width={20} height={15} src={locale.iconSrc} />
              &nbsp;
              <ListItemText>{locale.title}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Popover> */}
    </div>
  );
};

export default LanguageSelector;
