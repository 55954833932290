import * as React from 'react';
import { IntlProvider } from 'react-intl';
import useLanguageStore from 'store/language';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import getCurrentLocale from 'locales/get-current-locale';
import { Locale } from 'global/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionItem } from 'global/enums';
import LockTheUser from 'components/LockTheUser';
import usePrevious from 'hooks/usePrevious';
import paths from 'router/paths';
import useFetch from 'hooks/useFetch';
import { useWeb3ModalTheme } from '@web3modal/wagmi/react';
import { primaryColor } from 'themes';
import { useAccount, useNetwork } from 'wagmi';
import usePermission from 'store/permission';
import WrongNetworkFullPage from 'components/WrongNetwork';
import getCryptoPrice from 'helpers/get-crypto-price';
import useCurrency from 'store/currency';
import rtlPlugin from 'stylis-plugin-rtl';
import { Helmet } from 'react-helmet';
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const ConfigProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { pathname, search } = useLocation();

  const { chain, chains } = useNetwork();

  const navigate = useNavigate();
  const { getUserPermission, getSmartContractOwner } = useFetch();
  const locale = useLanguageStore(state => state.locale);
  const { address } = useAccount();
  const setPermission = usePermission(state => state.setPermission);
  // const { setThemeMode ,setThemeVariables} = useWeb3ModalTheme();
  // const { setTheme } = useWeb3ModalTheme();

  // Language configs
  const currentLocale = React.useMemo<Locale>(() => {
    const l = getCurrentLocale(locale);
    return l;
  }, [locale]);

  // UI configs
  React.useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  // React.useEffect(() => {
  //   setThemeMode('light')
  //   setThemeVariables({
  //     "--w3m-z-index":20000,
  //     "--w3m-accent": primaryColor,
  //   });
  //   // setTheme({
  //   //   themeMode: 'light',
  //   //   themeVariables: {
  //   //     '--w3m-accent-color': primaryColor,
  //   //     '--w3m-z-index': '2000000',
  //   //   },
  //   // });

  // }, []);


 

    // Redirect after connect
  const previousPathname = usePrevious(pathname);
  const previousSearch = usePrevious(search);

  React.useEffect(() => {
    if (pathname === paths.connect && previousPathname && pathname !== previousPathname) {
      navigate(`${paths.connect}?redirectTo=${previousPathname}${previousSearch}`, {
        replace: true,
      });
    }
  }, [pathname, previousPathname, previousSearch, navigate]);

  // User
  React.useEffect(() => {
    if (!!address) {
      setPermission({ isCourt: PermissionItem.UNKNOWN, isValidator: PermissionItem.UNKNOWN });
      getUserPermission(address).then(({ isCourt, isValidator }) => {
        setPermission({ isCourt, isValidator });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  // Currencies handler
  const setCurrencies = useCurrency(state => state.setCurrencies);
  const setCurrenciesFetchingError = useCurrency(state => state.setCurrenciesFetchingError);

  React.useEffect(() => {
    getCryptoPrice()
      .then(res => setCurrencies(res!))
      .catch(() => setCurrenciesFetchingError(true));
  }, [setCurrencies, setCurrenciesFetchingError]);

  // Get smart contract owner
  React.useEffect(() => {
    getSmartContractOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CacheProvider value={currentLocale.direction === 'ltr' ? cacheLtr : cacheRtl}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages} onError={() => null}>
        <LockTheUser />
        <Helmet>
          <style>
            {` body {
              direction:${currentLocale.direction}
          }`}
          </style>
        </Helmet>
        {chain && !chains.some(c => c.id === chain?.id) ? <WrongNetworkFullPage purpleSVG /> : children}
      </IntlProvider>
    </CacheProvider>
  );
};

export default ConfigProvider;
