import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  alpha,
  Box,
  Button,
  Divider,
  Grid,
  Drawer,
  IconButton,
  Typography,
  Avatar,
  MenuItem,
  Menu,
} from '@mui/material';
import { ReactComponent as Logo } from 'assets/logo-full.svg';
import HambergerMenu from 'assets/icons/svgs/hamberger-menu.svg';
import useNavigation, { connectWallet, disconnectWallet } from './use-navigations';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import useProfileImage from 'hooks/useProfileImage';
import paths from 'router/paths';
import { Navigation } from 'global/interfaces';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import formatAddress from 'utils/format-address';
import { WalletIcon } from 'assets/icons';
import MenuNavigation from './MenuNavigation';
import useBalance from 'hooks/useBalance';
import useDisconnect from 'hooks/useDisconnect';
import LanguageSelector from 'components/LanguageSelector';
import useData from 'store/data';
import formatNumber from 'utils/format-number';

const MainHeader = styled(Box)(({ theme }) => {
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha((theme as any).palette.background.paper, 0.5),
    backgroundImage: 'radial-gradient(rgba(0, 0, 0, 0) 1px, #fafafa 1px)',
    backgroundSize: '4px 4px',
    backdropFilter: 'blur(8px)',
    zIndex: 1200,
    paddingBottom: (theme as any).spacing(1),
    paddingTop: (theme as any).spacing(1),
    paddingLeft: (theme as any).spacing(2),
    paddingRight: (theme as any).spacing(2),
    '@supports not (backdrop-filter: blur(20px))': {
      backgroundColor: (theme as any).palette.background.paper,
    },
    [(theme as any).breakpoints.down('md')]: {
      paddingBottom: (theme as any).spacing(0),
      paddingTop: (theme as any).spacing(0),
    },
  };
});

const MainLayoutCustomLogo = styled(Logo)(({ theme }) => {
  return {
    width: '8rem',
    transform: 'translateY(3px)',
    [(theme as any).breakpoints.down('md')]: {
      width: '6rem',
    },
  };
});

const NavbarDivider = styled(Divider)(({ theme }) => {
  return { marginLeft: (theme as any).spacing(2), marginRight: (theme as any).spacing(2) };
});

const NavWrapperComponent = styled(Grid)(({ theme }) => {
  return {
    [(theme as any).breakpoints.down('md')]: { display: 'none' },
  };
});

const HamburgarMenuWrapper = styled(Grid)(({ theme }) => {
  return { display: 'none', [(theme as any).breakpoints.down('md')]: { display: 'block' } };
});

const Header: React.FC = () => {
  const { address } = useAccount();
  const { image } = useProfileImage();
  const [openMobileDrawer, setOpenMobileDrawer] = React.useState<boolean>(false);
  const { desktopNavigations, mobileNavigations } = useNavigation();
  const openConnector = useData(state => state.openConnector);
  const { disconnect } = useDisconnect();
  const { data: balance } = useBalance();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorElOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinkChildren = (n: Navigation): React.ReactNode => (
    <Button
      fullWidth
      color="inherit"
      onClick={() => {
        setOpenMobileDrawer(false);
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>{n.icon}</Grid>
        <Grid item>
          <Typography component="span" variant="body1">
            {n.title}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );



  const onDisconnectWallet = () => {
    disconnect();
  };




  return (
    <>
      <Drawer
        anchor="right"
        open={openMobileDrawer}
        onClose={() => {
          setOpenMobileDrawer(false);
        }}
      >
        <Box sx={{ width: 250 }} p={2}>
          <Box mb={2}>
            <Grid container>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpenMobileDrawer(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {address ? (
              <>
                <Box mb={2}>
                  <Link to={paths.userProfile}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      onClick={() => {
                        setOpenMobileDrawer(false);
                      }}
                    >
                      <Grid item>
                        <Avatar alt="Remy Sharp" src={image} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" sx={{ color: 'secondary.main' }}>
                          {formatAddress(address!)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <AccountBalanceWalletOutlinedIcon sx={{ fontSize: '1.5rem' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {formatNumber(balance?.formatted!, { decimals: 3 ,round:1})} {balance?.symbol}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <Box onClick={() => openConnector()}>{getLinkChildren(connectWallet)}</Box>
            )}
            {mobileNavigations.map(n => (
              <Box key={n!.id}>
                {n!.newTab ? (
                  <a href={n!.to} target="_blank" rel="noreferrer">
                    {getLinkChildren(n!)}
                  </a>
                ) : (
                  <Link to={n!.to}>{getLinkChildren(n!)}</Link>
                )}
              </Box>
            ))}
            {!!address && <Box onClick={() => onDisconnectWallet()}>{getLinkChildren(disconnectWallet)}</Box>}
          </Box>
        </Box>
      </Drawer>
      <MainHeader component="header" id="application-header">
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item>
            <Box pr={{ xs: 2, md: 10 }}>
              <Link to={{ pathname: paths.home }} style={{ display: 'block' }}>
                <MainLayoutCustomLogo />
              </Link>
            </Box>
          </Grid>
          <HamburgarMenuWrapper item flexGrow={1}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setOpenMobileDrawer(true);
                  }}
                >
                  <img src={HambergerMenu} alt="HambergerMenu" />
                </IconButton>
              </Grid>
            </Grid>
          </HamburgarMenuWrapper>
          <NavWrapperComponent item flexGrow={1}>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box pr={3}>
                  <Box component="nav">
                    <Grid
                      container
                      component="ul"
                      alignItems="center"
                      sx={{ listStyle: 'none', margin: 0, padding: 0 }}
                    >
                      {desktopNavigations.map((n, i) => (
                        <React.Fragment key={n!.id}>
                          {i === 0 ? null : <NavbarDivider flexItem orientation="vertical" />}
                          <Grid className={n.className} item component="li">
                            {n!.newTab ? (
                              <a href={n!.to} target="_blank" rel="noreferrer">
                                {n!.title}
                              </a>
                            ) : n.items ? (
                              <>
                                <a
                                  aria-label="more"
                                  id="long-button"
                                  className="pointer"
                                  aria-expanded={anchorElOpen ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                >
                                  {n.title}
                                </a>

                                <Menu anchorEl={anchorEl} open={anchorElOpen} onClose={handleClose}>
                                  {n.items.map(option => (
                                    <Link to={option.link}>
                                      <MenuItem key={option.link} onClick={handleClose}>
                                        {option.title}
                                      </MenuItem>
                                    </Link>
                                  ))}
                                </Menu>
                              </>
                            ) : (
                              <Link to={n!.to}>{n!.title}</Link>
                            )}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid display={'flex'} alignItems={'center'} item>
                {address ? (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <MenuNavigation />
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {formatNumber(balance?.formatted!, { decimals: 3,round:1 })} {balance?.symbol}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <WalletIcon sx={() => ({ width: 30, height: 30, transform: 'translateY(3px)' })} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    className="step1"
                    onClick={openConnector}
                    color="primary"
                    variant="outlined"
                    endIcon={connectWallet.icon}
                  >
                    {connectWallet.title}
                  </Button>
                )}
                <LanguageSelector />
              </Grid>
            </Grid>
          </NavWrapperComponent>
        </Grid>
      </MainHeader>
    </>
  );
};

export default Header;